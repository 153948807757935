import React from "react";
import { Box } from "@mui/material";

import { ScrollProvider } from "../contexts/scroll";

import Container from "../components/container";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";
import { PrivacyCopy } from "../lib/terms";
import { SECTION_SX } from "../styles/shared";

export default function PrivacyPage() {
  return (
    <PageWrapper
      pageDescription="StatusVista privacy policy"
      pageTitle="Privacy policy"
    >
      <ScrollProvider>
        <Header variant="primary" />
        <Box component="section" sx={SECTION_SX}>
          <Container maxWidth="md">
            <PrivacyCopy />
          </Container>
        </Box>
        <Footer />
      </ScrollProvider>
    </PageWrapper>
  );
}
